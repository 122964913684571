$theme-tan:#c1a76d;
$theme-brown:#837345;
$theme-yellow:#efe6b1;
$theme-dark-blue:#569aa3;
$theme-light-blue:#b5c9c3;
$theme-gray:#575442;



$text-color:#706f6f;

$font-stack:'Varela Round';
@import url(https://fonts.googleapis.com/css?family=Varela+Round);
@import "linear-gradient";
@import "bourbon";


/**
 * Responsive mixin. The media breakpoints are as defined
 * in the twitter bootstrap framework:
 *
 * - phone
 * - tablet-portrait
 * - tablet-landscape-desktop
 * - large-desktop
 *
 * Additional parameters for tagetting retina and non-retina
 * devices
 *
 * - retina
 * - non-retina
 *
 * Moreover, a specific value in px can be passed which is
 * used to generate a max-width media query.
 */
@mixin respond-to($media) {
    /* Landscape phones and down */
    @if $media == phone {
        @media (max-width: 480px) { @content; }
    }
    /* Landscape phone to portrait tablet */
    @else if $media == tablet-portrait {
        @media (max-width: 767px) {@content; }
    }
    /* Portrait tablet to landscape and desktop */
    @else if $media == tablet-landscape-desktop {
        @media (min-width: 768px) and (max-width: 992px) { @content; }
    }
    /* Large desktop */
    @else if $media == large-desktop {
        @media (min-width: 1200px) { @content; }
    }
    // Non-Retina
    @else if $media == non-retina {
        @media screen and (-webkit-max-device-pixel-ratio: 1) { @content; }
    }
    // Retina Only
    @else if $media == retina {
        @media screen and (-webkit-min-device-pixel-ratio: 2) { @content; }
    }
    @else if $media == print{
        @media print { @content; }
    }


    //bootstrap helpers

    @else if $media ==xs {
        @media (max-width:768px){@content;}
    }

    @else if $media ==sm-only {
        @media (max-width: 992px) and (min-width:768px){ @content; }
    }
    @else if $media ==sm {
        @media (max-width: 992px) { @content; }
    }

    @else if $media ==md {
        @media (min-width:992px){@content;}
    }

    @else if $media ==lg {
        @media (max-width:992px){@content;}
    }


    // Specific max width
    @else {
        @media only screen and (max-width: #{$media}px) { @content; }
    }
}




body {
    font-family: 'Lato' !important;
}

.fa-btn {
    margin-right: 6px;
}



.nav.navbar-nav.public{
    @include respond-to("large-desktop"){
        li{
            a{
                color:$text-color;
                text-transform:uppercase;
                padding:37px 27px 47px;

                &.two-line{
                    padding-top:27px;
                    padding-bottom:37px;
                    text-align:center;
                }
            }
            &:nth-child(6n+1){
                border-top:$theme-dark-blue 10px solid;
                &.active{
                    background-color:$theme-dark-blue;
                }
            }

            &:nth-child(6n+2){
                border-top:$theme-tan 10px solid;
                &.active{
                    background-color:$theme-tan;
                }
            }

            &:nth-child(6n+3){
                border-top:$theme-brown 10px solid;
                &.active{
                    background-color:$theme-brown;
                }
            }

            &:nth-child(6n+4){
                border-top:$theme-light-blue 10px solid;
                &.active{
                    background-color:$theme-light-blue;
                }
            }

            &:nth-child(6n+5){
                border-top:$theme-yellow 10px solid;
                &.active{
                    background-color:$theme-yellow;
                }
            }
            &:nth-child(6n+6){
                border-top:$theme-gray 10px solid;
                &.active{
                    background-color:$theme-gray;
                }
            }

            &.active{
                a{
                    color:#fff;
                    &:hover{
                        color:$text-color;
                    }
                }
            }
        }
    }


    @media(max-width:1200px){
        float:none;
        width:100%;
        br {
            display:none;
        }

    }

}

.icon-bar{
    border:#fff 1px solid;
    &:nth-child(3n+0){
        border:$theme-dark-blue 1px solid;
    }
    &:nth-child(3n+1){
        border:$theme-tan 1px solid;
    }
    &:nth-child(3n+2){
        border:$theme-light-blue 1px solid;
    }
}

.navbar-brand{
    @media(max-width:1200px){
        padding-top:3px !important;
    }
    @include respond-to("phone"){
        width:80%;
    }
}

.navbar-header{
    .navbar-toggle{
        margin-top:22px;
        margin-bottom:22px;
        border:solid rgba(216,216,216,0.54);
    }
}


.navbar{
    margin-bottom:-1px !important;
    .container{
        &.full{
            @media(min-width:1200px){
                width:1200px;
                padding-left:0;
                padding-right:0;
            }
        }
    }
}

.content-wrapper{
    body{
        color:$text-color !important;
        font-size:16px;
        font-family:$font-stack !important;
    }


    h1{
        font-size:45.82px;
    }
    h2{
        font-size:25px;
    }

    .container{
        &.full{
            @media(min-width:1200px){
                width:1200px;
                padding-left:0;
                padding-right:0;
            }
        }
    }

    .color{
        &.brown{ color:$theme-brown; }
        &.tan{color:$theme-tan;}
        &.yellow{color:$theme-yellow;}
        &.dark-blue{color:$theme-dark-blue;}
        &.light-blue{color:$theme-light-blue;}
        &.gray{color:$theme-gray;}
        &.white{color:#fff;}
        &.text-color{color:$text-color;}

        &.bg-dark-blue{ background-color:$theme-dark-blue; }
        &.bg-light-blue{background-color:$theme-light-blue;}
        &.bg-tan{background-color:$theme-tan;}
        &.bg-yellow{background-color:$theme-yellow;}
        &.bg-brown{background-color:$theme-brown;}
        &.bg-gray{background-color:$theme-gray;}
        &.bg-white{background-color:#fff;}


    }

    .lesson{
        background-color:#fff;
        border-radius:25px;
        display:block;
        padding:38px 30px;

        h2{
            letter-spacing:1px;
        }
        #content {
            strong{
                color:$theme-dark-blue;
            }
        }
    }

    .word{
        h4{
            &:first-letter{
                text-transform:uppercase;
            }
        }
    }

    .paper-grid{
        background-image:url(/images/paper-grid.png);
        background-repeat:repeat;
    }
    .chapter-card,
    .lesson-card
    {
        border-radius:25px;
        margin-top:75px;
        max-width:420px;
        margin-right:auto;
        margin-left:auto;

        @include respond-to("sm"){
            float:none;
        }


        .card-body{
            background-color:#fff;
            padding:27px 25px 33px;

            .lessons{
                color:$theme-dark-blue;
            }
            .learn-more{
                color:$theme-tan;
                position:relative;
                top:-10px;
                i{
                    position:absolute;
                    top:8px;
                    font-size:23px;
                    right:-10px;
                }
            }
        }

        .image{
            border-radius: 25px 25px 0 0;
            min-height:230px;
            background-size:cover;
            background-position:center;
        }
    }

    .lesson-card .card-body{
        border-radius:25px 25px 25px 25px;
        padding:0px 25px 33px;
    }

    .chapter-card .card-body{
        border-radius:0 0 25px 25px;
    }



    .card-body{
        position:relative;
        h2{
            margin:0;
        }

        .lessons{
            padding-right:15px;
            font-size:19px
        }
        .pull-right{
            text-align:right;
        }
        p{
            margin-bottom:0;
        }
        .learn-more{
            font-size:12.5px;
        }

    }




    .content-section{
        padding-top:65px ;
        padding-bottom:65px ;
    }
    .CTA-section{
        padding-top:50px ;
        padding-bottom:50px ;
    }
}


.grade-wrapper{
    .chapter-card{
        @include respond-to("md"){
            &:nth-child(-n+3){
                margin-top:0px;
            }

        }
        @include respond-to("sm-only"){
            &:nth-child(-n+1){
                margin-top:0px;
            }

        }
        @include respond-to("xs"){
            &:nth-child(-n+1){
                margin-top:0px;
            }

        }
    }
}

.btn{
    &.round{
        border-radius:100px;
        padding:18px 51px;
        font-size:21px;
        margin:45px auto 5px;
        border:none;


        &.bg-light-blue{
            @include linear-gradient(#a5d0e3,#82b0c3);
        }

        &.bg-dark-blue{
            @include linear-gradient(#75acb3, #4c868e );
            &.white{
                &:hover{
                    color:#f0f0f0;
                }
            }
        }

        &.bg-yellow{
            @include linear-gradient(#f2eabe,#d1c99b);
        }
        &.bg-tan{
            @include linear-gradient(#ccb787,#a8915f);

            &:hover{
                color:#ffffff;
            }
        }

        &.bg-white{
            background-color:#ffffff;
        }

    }
    &.small{
        font-size:12.25px;
        padding:10px 40px;
        width:initial;
    }
    &.ghost{
        background:none !important;
        border:3px solid $theme-dark-blue;
        padding:15px 48px;

    }
}

a{
    &:hover{
        text-decoration:none;
    }
}

iframe{
    max-width:100%;
}

#footer{
    @include respond-to("print"){
        display:none;
    }

    .nav{
        padding:10px 0;
        float:initial;

        @media(max-width:1200px){
            width:auto !important;
            text-align:center;
        }

        li{
            text-transform:uppercase;
            font-size:16px;
            padding:0 14px;
            a{
                color:$text-color;
            }
            br {
                display:none;
            }

        }
    }
    .copyright{
        font-size:16px;
        color:$text-color;
    }
}

.faq{
    width:100%;
    text-align:left !important;
    border-radius:25px !important;
    margin-top:20px !important;
    position:relative;

    @include respond-to('sm'){
        padding:18px 0 !important;
        font-size:18px !important;
    }
    @include respond-to('xs'){
        padding:18px 0 !important;
        font-size:18px !important;
    }


    & > .header{
        display:block;
        padding:7px 38px;
        font-size:1.2em;
        white-space:normal;
    }


    &.in{
        & > .header{
            &::before{
                @include transform(rotate(180deg));
                content:url(/images/arrow.png) ;
                display:block;
                position:absolute;
                left:31px;
                top:28px;
                @include respond-to("sm"){
                    left:3px;
                }
            }
        }
    }

    & > .header{
        &::before{
            content:url(/images/arrow.png) ;
            display:block;
            position:absolute;
            left:31px;
            top:28px;

            @include respond-to("sm"){
                left:3px;
            }
        }
    }
    p{
        padding-top:30px;
        width:100%;
        padding-left:35px;
        font-size:.8em;
        white-space: normal;

        @include respond-to("sm"){
            padding-left:15px;
            padding-right:15px;
        }
    }
}


.card-wrapper{
    padding:18px 63px;
    font-size:21px;
    margin:20px auto 5px;
    border:none;
    width:100%;
    text-align:left !important;
    border-radius:25px !important;
    position:relative;
    background-color:#ffffff;
    padding-top:60px;

    p{
        font-size: 1.2em;
    }
}

#feedback{
    .card-wrapper{
        @include respond-to("lg"){
            padding:30px 30px !important;
        }
    }
    input[type="checkbox"]{
        position:absolute;
        left:-9999999px;
        height:0;
        &:checked + label .custom-checkbox{

            &:after{
                content: '';
                position: absolute;
                width: 15px;
                height: 7px;
                background: transparent;
                top: 6px;
                left: 3px;
                border: 3px solid $theme-dark-blue;
                border-top: none;
                border-right: none;
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }


        }
    }
    .custom-checkbox{
        display:inline-block;
        width:25px;
        height:25px;
        background-color:transparent;
        border:2px solid #AAAAAA;
        border-radius:5px;
        transition:all .5 ease;
        cursor:pointer;
        position:relative;
        top:7px;
    }
}

.rating{
    padding:9px 0 46px;
    list-style-type: none;
    li{
        display:inline-block;
        margin:0 ;
        position:relative;
        width:80px;
        label{
            display:block;
            font-size:.8em;
            color:$text-color;
            height:60px;
            position:relative;
            top:-25px;
            line-height: 6;
            z-index: 2;
            font-weight: 400;
            text-align:center;
        }
        input{
            display:none;
            visibility: hidden;

            &:checked{
                ~ .check{
                    .inside{
                        border: 2px solid $theme-dark-blue;
                        border-radius: 100%;
                        height: 17px;
                        width: 17px;
                        top:2px;
                        left:2px;
                        position: relative;
                        background-color: $theme-dark-blue;
                    }

                }
            }
        }
        .check{
            display: block;
            position: relative;
            border: 2px solid #AAAAAA;
            border-radius: 100%;
            height: 25px;
            width: 25px;
            top: 00px;
            left: 0px;
            right : 0px;
            margin:0 auto;
            z-index: 0;
            transition: border .25s linear;
            -webkit-transition: border .25s linear;

            &:before{
                display: block;
                position: absolute;
                content: '';
                border-radius: 100%;
                height: 15px;
                width: 15px;
                top: 5px;
                left: 5px;
                margin: auto;
                transition: background 0.25s linear;
                -webkit-transition: background 0.25s linear;
            }
        }
    }

}

.page-title{
    margin-bottom: 60px;
    letter-spacing: 2px;
}




#text-fields{
    padding:0;

    .col-md-6:nth-child(even){

        @include respond-to("large-desktop"){ padding-left:0; }

    }
    .col-md-6:nth-child(odd){
        @include respond-to("large-desktop"){ padding-right:0; }
    }
    .col-md-12{
        @include respond-to("large-desktop"){ padding:0; }
    }

    label{
        color:$text-color;
        font-size:1.2em;
        font-weight: 500;
        margin-bottom:14px;
    }
    input,
    textarea,
    select{
        border: 2px solid #AAAAAA;
        border-radius: 25px;
    }

    &.no-border{
        input,textarea{
            border: none;
            border-radius: 25px;
        }
    }

    input{
        height:75px;
    }
}

.teks{
    .title{
        color: $theme-dark-blue;
    }
    .category{
        display:inline-block;
        margin-right:15px;
        vertical-align:top;
    }

    .tek{
        position:relative;
        &:hover{
            text-decoration:underline;
            cursor:pointer;
            .info{
                display:block;
                position:absolute;
                max-width:700px;
                min-width:300px;
                background-color:#fff;
                border-radius:25px;
                display:block;
                padding:38px 30px;
                box-shadow:0px 2px 32px -11px #000;
                z-index:10;
                top:-40px;
                left:50px;
            }
            &:before{
                content:' ';
                position:absolute;
                left:35px;
                z-index:11;
                top:-4px;
                left:0;
                display:block;

                @include triangle(30px, white,left);
                width:50px;
            }

        }
        .info{
            display:none;
        }
    }

    .tek-list{
        padding-left:0px;
        li{
            list-style-type:none;
        }
    }
}
#wifphotos-wrapper{
    @include respond-to("print"){
        page-break-before:always;
        page-break-after:always;
        height:auto !important;
    }



    #wifphotos{
        @include respond-to("print"){
            height:auto !important;
        }



        .photo{


            @include respond-to("print"){
                position:relative !important;
                top:0 !important;
                left:0 !important;
            }


            margin:1%;
            display:inline-block;
            width:47%;
            height:auto;
            vertical-align:top;
            cursor:pointer;
            img{
                width:100%;
            }

        }
    }
}
.lg{
    .lg-img-wrap{
        padding-right:400px !important;
    }
    .lg-toolbar{
        padding-right:400px !important;
    }
    .lg-next,
    .lg-toggle-thumb{
        right:420px !important;
    }
    .inner{
        padding:60px 10px 0;
        text-align:left;
        background-color:#0f0f0f;
        color:#fff;
        height:100%;
        position:absolute;
        top:0;
        right:0;
        width:400px;

    }
}


#breadcrumbs{


}
#lessonSearch{
    input{
        height:auto;
    }
    input,
    textarea,
    select{
        border-radius: 5px;
    }
}

#feedback-button{


    @include respond-to("print"){
        display:none;
    }

    position:fixed;
    bottom:0;
    right:10px;
    background-color:$theme-dark-blue;
    padding:10px 15px;
    border-radius:15px 15px 0 0;

    a{
        color:#fff;
        &:hover{
            color:#f0f0f0;
        }
    }
}


.snake-bg{
    background-color:black;
    overflow:hidden; 
    background-image:url(/images/snake.jpg);
    background-position:center;
    background-repeat:no-repeat;
    background-position-x:75%;
    background-size:650px;
    @include respond-to("lg"){
        background-position-x:50%;
        background:linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url("/images/snake.jpg");
        background-position:center;
        background-repeat:no-repeat;
        background-color:black;
    }
    @media(max-width:480px){
        padding-top:0px !important;
    }
}
.frog-bg{
    background-color:black;
    overflow:hidden; 
    background-image:url(/images/frog.jpg);
    background-position:center;
    background-repeat:no-repeat;
    background-position-x:75%;
    background-size:650px;
    @include respond-to("lg"){
        background-position-x:50%;
        background:linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url("/images/frog.jpg");
        background-position:center;
        background-repeat:no-repeat;
        background-color:black;
    }
    @media(max-width:480px){
        padding-top:0px !important;
    }
}
.hero-text{
    @include respond-to("lg"){
        text-align:center;
    }

}

#sneak-peek-button{

    @include respond-to("lg"){
        display:block;
        text-align:center;
    }
}
#about-section{
    #about-description{
        background:white;
        box-shadow:white 0px 0px 30px 40px;
    }
    .read-more{
        @include respond-to("tablet-portrait"){
            display:block;
            text-align:center;
        }
    }
}
.submit{
    @include respond-to("lg"){
        display:block !important;
    }
}

.desktop-no-padding-left{
    @include respond-to("md"){
        padding-left:0 !important;
    }
}

.float-left-of-image{
    float:left;
    right:0;
    left:0;
    position:absolute;
    margin:0 auto;

    @include respond-to("lg"){
        float:none;
        position:relative;
    }
}
.float-right-of-image{
    div.col-md-12{
        padding:90px 0 120px 35px;
        @include respond-to("lg"){
            padding:90px 0 120px 0px;
        }
    }
}

.pull-under{
    @include respond-to("lg"){
        position:absolute;
        width:100%;
    }
}

.lg-no-float{
    @include respond-to("lg"){
        float:none !important;
    }

}

.no-padding-on-first-and-last{
    @include respond-to("large-desktop"){
        div[class^="col-"],
        div[class*="col-"]{
            &:first-child{
                padding-left:0;
            }
            &:last-child{
                padding-right:0;
            }
        }

    }
}


.flash{
    position:absolute;
    left:0;
    right:0;


    .alert{
        font-size:25.2px;
        color:#fff !important;
        margin-top:50px;
        margin-bottom:-30px;
        text-align:center;
    }

    .alert-success{

        background:#77ccbd !important;

    } 
    .alert-danger{
        background:#e42c2c !important;
    }
    + * {
        & > *{
            padding-top:100px !important;
        }
        & > *.row{
            padding-top:160px !important;
        }

        & > *.CTA-section{
            padding-top:160px !important;
        }
    }
}

#lessonSideSection,
#lessonContentSection{

    @include respond-to("print"){
        float:none !important;
        width:100% !important;
    }
}



@include respond-to("print"){
    *{
        float:none !important;
    }
}



#purchaseSection{
    p{
        font-size:.8em;
    }
    .tab-content{
        padding:40px 0;
    }


    #text-fields{
        input,textarea{
            background-color:#fff;
            border:solid 1px #afafaf;
        }
    }


    .nav.nav-pills{
        text-align:center;
        li{
            float:none;
            display:inline-block;
            max-width:210px;

            margin:0 10px !important;

            &.active{
                @extend .btn ;
                @extend .round ;
                @extend .color ;
                @extend .bg-dark-blue ;
                @extend .white ;
                a{
                    background-color:initial;
                }

            }
            &:not(.active){
                @extend .btn ;
                @extend .round ;
                @extend .bg-dark-blue ;
                @extend .white ;
                @extend .ghost;
                a{
                    color: $theme-dark-blue;
                }
            }
            a{
                &:hover{
                    background-color:initial;
                }
            }


            @media(max-width:992px){
                width:100%;
                display:block;
                text-align:center ;
                margin:10px auto !important;
                a{
                    margin:0 auto;
                }
            }


        }
    }

    #school-table{
        margin-top:50px;
        padding:15px;

        .school{
            border: solid 2px #dedede;
            border-radius:25px;
            padding:30px 20px;
            margin-bottom:20px;

        }

        .header{
            text-align:center;
            padding:10px 0;
            margin:0 -15px 30px;
            font-size:1.5em;
            .label{
                color:$theme-dark-blue;
                font-size:1.2em;
            }
            .price{
                font-size:.6em;

            }
        }
        .row{
            margin-bottom:10px;
            border-bottom:solid 1px #f0f0f0;
            padding:10px 17px;
            &:last-child{
                border-bottom:none;
            }
        }
        .data{
            text-align:center;
        }

        input{
            width:auto;
            border:none;
            border-bottom:dotted 1px #000;
            text-align:right;
            max-width:85px;
            float:left;

            @include respond-to("tablet-portrait"){
                max-width:100%;
            }


        }
        button[type="submit"]{
            height:50px;
            font-size:1em;
            margin-top:30px;

        }

        .btn-primary:hover,
        .btn-primary:focus{
            background-position:initial;
        }

        button{
            background-color:rgba(0,0,0,0);
            border:solid 1px $theme-dark-blue;
            line-height:0;
            margin-right:-5px;
            padding:12px 7px;

            & ~ button{
                background-color:$theme-dark-blue;
                color:#fff;
            }
        }
        .total{
            max-width:85px;
            float:left;
            padding:0;
            text-align:right;
            &:before{
                content:'$';
            }
        }
    }
    .extra{
        font-size:.8em;
        text-align:center;
    }


}



